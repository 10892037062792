import actions from "./actions";

const initState = {
  marketing_items: {
    list: [],
    totalItems: 0,
    loading: false,
  },
};

export default function marketingItemsReducer(state = initState, action) {
  switch (action.type) {
    //#region CRUD
    case actions.GET_MARKETING_ITEMS:
      return {
        ...state,
        marketing_items: { ...state.marketing_items, loading: true },
      };
    case actions.GET_MARKETING_ITEMS_SUCCESS: {
      let { data, totalItems } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        marketing_items: {
          ...state.marketing_items,
          list: res,
          loading: false,
          totalItems: totalItems,
        },
      };
    }
    case actions.GET_MARKETING_ITEMS_ERROR:
      return {
        ...state,
        marketing_items: {
          ...state.marketing_items,
          loading: false,
          totalItems: 0,
          list: [],
        },
      };
    //#endregion

    default:
      return state;
  }
}
