import actions from "./actions";

const [TOTAL, MONTHLY] = ["1", "2"];
const initState = {
  reports: {
    list: [],
    brandName: null,
    budgetYear: null,
    loading: false,
    totalItems: 0,
    brandCurrency: null,
    marketingBudget: 0,
  },
};

export default function reportsReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_REPORTS:
      return { ...state, reports: { ...state.reports, loading: true } };

    case actions.GET_REPORTS_SUCCESS: {
      const { data, type } = action.payload;
      const { brandName, budgetYear, total, brandCurrency, marketing_budget } =
        data;
      let array = data?.list?.length ? data?.list : [];
      switch (type) {
        case TOTAL: {
          let obj = {
            name: "TOTAL",
            actual: data?.totalActual,
            forecast: data?.totalForecast,
            id: "total",
          };
          array = [...data?.list];
          array.push(obj);
          break;
        }

        case MONTHLY: {
          const { summaryTotal } = data;
          let obj = { ...summaryTotal, key: "total" };
          array.push(obj);
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        reports: {
          ...state.reports,
          list: array,
          loading: false,
          brandName: brandName,
          budgetYear: budgetYear,
          totalItems: total,
          brandCurrency: brandCurrency,
          marketingBudget: marketing_budget,
        },
      };
    }

    case actions.GET_REPORTS_ERROR:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          totalItems: 0,
          list: [],
          brandCurrency: null,
        },
      };

    default:
      return state;
  }
}
