import * as marketingItemsService from "@iso/services/marketingItems";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getMarketingItemsSaga() {
  yield takeEvery(
    actions.GET_MARKETING_ITEMS,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          marketingItemsService.getMarketingItemsService,
          model
        );
        if (response.data.success) {
          var data = response.data.data.list;
          var totalItems = response.data.data.total;
          yield put({
            type: actions.GET_MARKETING_ITEMS_SUCCESS,
            payload: { data, totalItems },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_MARKETING_ITEMS_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_MARKETING_ITEMS_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* deleteMarketingItemSaga() {
  yield takeEvery(
    actions.DELETE_MARKETING_ITEM,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          marketingItemsService.deleteMarketingItemService,
          model
        );
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createMarketingItemSaga() {
  yield takeEvery(
    actions.CREATE_MARKETING_ITEM,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          marketingItemsService.createMarketingItemService,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
export function* updateMarketingItemSaga() {
  yield takeEvery(
    actions.UPDATE_MARKETING_ITEM,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          marketingItemsService.updateMarketingItemService,
          model
        );
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* importMarketingItemsSaga() {
  yield takeEvery(
    actions.IMPORT_MARKETING_ITEMS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        var response = yield call(
          marketingItemsService.importMarketingItemsService,
          body
        );
        if (response.status === 201) {
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `ImportItemsResult_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          yield _cbSuccess();
        } else {
          if (response.status === 202) {
            _cbError("FILE_MALFORMED");
          }
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* downloadTemplateSaga() {
  yield takeEvery(
    actions.DOWNLOAD_TEMPLATE,
    function* ({ _cbSuccess, _cbError }) {
      try {
        var response = yield call(
          marketingItemsService.downloadTemplateService
        );
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `TemplateImportItems_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* exportMarketingItemsSaga() {
  yield takeEvery(
    actions.EXPORT_MARKETING_ITEMS, function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          marketingItemsService.exportMarketingItemsService, model
        );
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `MarketingItems_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getMarketingItemsSaga),
    fork(deleteMarketingItemSaga),
    fork(createMarketingItemSaga),
    fork(updateMarketingItemSaga),
    fork(importMarketingItemsSaga),
    fork(downloadTemplateSaga),
    fork(exportMarketingItemsSaga),
  ]);
}
