import authSaga from "@iso/redux/auth/saga";
import brandsSaga from "@iso/redux/brands/saga";
import commonSaga from "@iso/redux/common/saga";
import consigneeSaga from "@iso/redux/consignee/saga";
import invoicesSaga from "@iso/redux/invoices/saga";
import marketingItemsSaga from "@iso/redux/marketingItems/saga";
import quoteSaga from "@iso/redux/quotes/sagas";
import reportSaga from "@iso/redux/reports/saga";
import suppliersSaga from "@iso/redux/suppliers/saga";
import userSaga from "@iso/redux/users/saga";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    authSaga(),
    commonSaga(),
    userSaga(),
    consigneeSaga(),
    suppliersSaga(),
    marketingItemsSaga(),
    invoicesSaga(),
    brandsSaga(),
    reportSaga(),
    quoteSaga(),
  ]);
}
