const actions = {
  GET_INVOICES_LIST: "GET_INVOICES_LIST",
  GET_INVOICES_LIST_SUCCESS: "GET_INVOICES_LIST_SUCCESS",
  GET_INVOICES_LIST_ERROR: "GET_INVOICES_LIST_ERROR",

  CREATE_INVOICE: "CREATE_INVOICE",

  DOWNLOAD_INVOICE: "DOWNLOAD_INVOICE",
  DELETE_INVOICE: "DELETE_INVOICE",

  RESET_INVOICES_LIST: "RESET_INVOICES_LIST",

  GET_INVOICE: "GET_INVOICE",
  // GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  // GET_INVOICE_ERROR: "GET_INVOICE_ERROR",

  RESET_INVOICE: "RESET_INVOICE",

  getInvoicesList: (model, _cbError) => ({
    type: actions.GET_INVOICES_LIST,
    payload: { model },
    _cbError,
  }),

  deleteInvoice: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_INVOICE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  createInvoice: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_INVOICE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  resetInvoicesList: () => ({
    type: actions.RESET_INVOICES_LIST,
  }),

  getInvoice: (model, _cbSuccess, _cbError) => ({
    type: actions.GET_INVOICE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  resetInvoice: () => ({
    type: actions.RESET_INVOICE,
  }),
};

export default actions;
