import actions from "./actions";

const initState = {
  invoices: {
    list: [],
    totalItems: 0,
    loading: false,
    brandName: null,
    budgetYear: null,
    brandCurrency: null,
    listYear: [],
  },
  invoice: {
    detail: {},
    loading: false,
    invoiceId: null,
    type: null,
  },
};

export default function invoicesReducer(state = initState, action) {
  switch (action.type) {
    //#region INVOICE LIST
    case actions.GET_INVOICES_LIST:
      return { ...state, invoices: { ...state.invoices, loading: true } };
    case actions.GET_INVOICES_LIST_SUCCESS: {
      let {
        data,
        totalItems,
        brandName,
        budgetYear,
        brandCurrency,
        years,
      } = action.payload;
      let res = data ? data : [];
      return {
        ...state,
        invoices: {
          ...state.invoices,
          list: res,
          loading: false,
          totalItems: totalItems,
          brandName: brandName,
          budgetYear: budgetYear,
          brandCurrency: brandCurrency,
          listYear: years,
        },
      };
    }
    case actions.GET_INVOICES_LIST_ERROR:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          totalItems: 0,
          list: [],
          budgetYear: null,
          brandCurrency: null,
          brandName: null,
          listYear: [],
        },
      };

    case actions.RESET_INVOICES_LIST:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          totalItems: 0,
          list: [],
        },
      };
    //#endregion

    // case actions.GET_INVOICE:
    //   return {
    //     ...state,
    //     invoice: {
    //       ...state.invoice,
    //       loading: true,
    //       invoice: {},
    //       invoiceId: null,
    //     },
    //   };

    // case actions.GET_INVOICE_SUCCESS: {
    //   let { data, invoiceId, isPreview } = action.payload;
    //   let res = data ? data : {};
    //   const { listExpense } = data;
    //   let array = [];
    //   if (listExpense?.length) {
    //     listExpense.map((item, index) => {
    //       let obj = {
    //         ...item,
    //         serial: index + 1,
    //       };
    //       return array.push(obj);
    //     });
    //   }
    //   res.listExpense = array;
    //   return {
    //     ...state,
    //     invoice: {
    //       ...state.invoice,
    //       detail: res,
    //       loading: false,
    //       invoiceId: invoiceId,
    //       type: isPreview,
    //     },
    //   };
    // }
    // case actions.GET_INVOICE_ERROR:
    //   return {
    //     ...state,
    //     invoice: {
    //       ...state.invoice,
    //       loading: false,
    //       detail: {},
    //       invoiceId: null,
    //     },
    //   };

    case actions.RESET_INVOICE:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          loading: false,
          detail: {},
          invoiceId: null,
        },
      };

    default:
      return state;
  }
}
