const actions = {
  GET_SUPPLIERS: "GET_SUPPLIERS",
  GET_SUPPLIERS_SUCCESS: "GET_SUPPLIERS_SUCCESS",
  GET_SUPPLIERS_ERROR: "GET_SUPPLIERS_ERROR",

  CREATE_SUPPLIER: "CREATE_SUPPLIER",
  UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
  DELETE_SUPPLIER: "DELETE_SUPPLIER",

  //#region CRUD
  getSuppliers: (model, _cbError) => ({
    type: actions.GET_SUPPLIERS,
    payload: { model },
    _cbError,
  }),
  deleteSupplier: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_SUPPLIER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  createSupplier: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_SUPPLIER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  updateSupplier: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_SUPPLIER,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  //#endregion
};

export default actions;
