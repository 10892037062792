import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getSuppliersService = (model) => {
  var url = ApiRouters.SUPPLIERS;
  return api.get(url, { params: model });
};
export const deleteSupplierService = (model) => {
  var url = `${ApiRouters.SUPPLIERS}`;
  return api.delete(url, { params: model });
};
export const createSupplierService = (body) => {
  var url = ApiRouters.SUPPLIERS;
  return api.post(url, body);
};
export const updateSuppliers = (model) => {
  var url = `${ApiRouters.SUPPLIERS}/${model.id}`;
  return api.put(url, model.data);
};
