import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getInvoicesListService = (model) => {
  var url = ApiRouters.INVOICE;
  return api.get(url, { params: model });
};
export const deleteInvoiceService = (model) => {
  var url = `${ApiRouters.INVOICE}`;
  return api.delete(url, { params: model });
};
export const createInvoiceService = (body) => {
  var url = `${ApiRouters.INVOICE}`;
  return api.post(url, body);
};
export const getInvoiceService = (model) => {
  var url = `${ApiRouters.INVOICE}/GetBillInvoiceById`;
  return api.get(url, { params: model });
};
