import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";

export const getUsers = (body) => {
  var url = ApiRouters.USER;
  return api.get(url, { params: body });
};
export const deleteUser = (model) => {
  var url = `${ApiRouters.USER}`;
  return api.delete(url, { params: model });
};
export const getUserDetail = (id) => {
  var url = `${ApiRouters.USER}/${id}`;
  return api.get(url);
};
export const createUser = (body) => {
  var url = ApiRouters.USER;
  return api.post(url, body);
};
export const updateUser = (body) => {
  var url = `${ApiRouters.USER}/${body.id}`;
  return api.put(url, body);
};
export const resetPassword = (body) => {
  var url = `${ApiRouters.USER}/resetPassword/${body.id}`;
  return api.put(url);
};
export const userAccessPermission = (id) => {
  var url = `${ApiRouters.USER}/${id}/Roles`;
  return api.get(url);
};
export const updateUserAccessPermission = (body) => {
  var url = `${ApiRouters.USER}/${body.id}/Roles`;
  return api.put(url, body);
};
