import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}
export const getMarketingItemsService = (body) => {
  var url = ApiRouters.MARKETING_ITEM;
  return api.get(url, { params: body });
};
export const getMarketingItemService = (id) => {
  var url = `${ApiRouters.MARKETING_ITEM}/${id}`;
  return api.get(url);
};
export const deleteMarketingItemService = (model) => {
  var url = `${ApiRouters.MARKETING_ITEM}`;
  return api.delete(url, { params: model });
};
export const createMarketingItemService = (body) => {
  var url = ApiRouters.MARKETING_ITEM;
  return api.post(url, body);
};
export const updateMarketingItemService = (body) => {
  var url = `${ApiRouters.MARKETING_ITEM}/${body.id}`;
  return api.put(url, body);
};
export const importMarketingItemsService = (body) => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.MARKETING_ITEM}/ImportMarketingItem`;
  return axios(optionsAxios(url, "post", null, body));
};
export const downloadTemplateService = () => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.MARKETING_ITEM}/Template/ImportMarketingItem`;
  return axios(optionsAxios(url, "get", null, null));
};
export const exportMarketingItemsService = (model) => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.MARKETING_ITEM}/Template/ExportMarketingItem`;
  return axios(optionsAxios(url, "get", model, null));
};
