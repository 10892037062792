import * as quoteServices from "@iso/services/quotes";
import moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

export function* getQuotesListSaga() {
  yield takeEvery(actions.GET_QUOTES_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(quoteServices.getQuotesListService, model);
      if (response.data.success) {
        const { list, total, years } = response.data.data;
        yield put({
          type: actions.GET_QUOTES_LIST_SUCCESS,
          payload: { list, total, years },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_QUOTES_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_QUOTES_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteQuoteSaga() {
  yield takeEvery(
    actions.DELETE_QUOTE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(quoteServices.deleteQuoteService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createQuoteSaga() {
  yield takeEvery(
    actions.CREATE_QUOTES,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(quoteServices.createQuoteService, model);
        if (response.data.success) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateQuoteSaga() {
  yield takeEvery(
    actions.UPDATE_QUOTE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(quoteServices.updateQuoteService, model);
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* uploadQuoteLogoSaga() {
  yield takeEvery(
    actions.UPLOAD_QUOTE_LOGO,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { body } = payload;
      try {
        const response = yield call(quoteServices.uploadQuoteLogoService, body);
        if (response.data.success) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getQuoteTemplateSaga() {
  yield takeEvery(
    actions.GET_QUOTE_TEMPLATE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          quoteServices.getQuoteTemplateService,
          model
        );
        if (response.data.success) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* exportQuotationExcelSaga() {
  yield takeEvery(
    actions.EXPORT_QUOTATION_EXCEL, function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(
          quoteServices.exportQuotationExcelService, model
        );
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH:mm");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName = `Quotation_Excel_${`${date}`}.xlsx`;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getQuoteItemsListSaga() {
  yield takeEvery(
    actions.GET_QUOTE_ITEMS_LIST,
    function* ({ payload, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          quoteServices.getQuoteItemsListService,
          model
        );
        if (response.data.success) {
          const { data } = response.data;
          yield put({
            type: actions.GET_QUOTE_ITEMS_LIST_SUCCESS,
            payload: { data },
          });
        } else {
          let { message } = response.data;
          yield _cbError(message);
          yield put({
            type: actions.GET_QUOTE_ITEMS_LIST_ERROR,
          });
        }
      } catch (e) {
        yield put({
          type: actions.GET_QUOTE_ITEMS_LIST_ERROR,
        });
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* deleteQuoteItemSaga() {
  yield takeEvery(
    actions.DELETE_QUOTE_ITEM,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          quoteServices.deleteQuoteItemService,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createQuoteItemsSaga() {
  yield takeEvery(
    actions.CREATE_QUOTE_ITEMS,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          quoteServices.createQuoteItemsService,
          model
        );
        if (response.data.success) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* updateQuoteItemSaga() {
  yield takeEvery(
    actions.UPDATE_QUOTE_ITEM,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          quoteServices.updateQuoteItemService,
          model
        );
        if (response.data.success) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getQuotesListSaga),
    fork(deleteQuoteSaga),
    fork(updateQuoteSaga),
    fork(createQuoteSaga),
    fork(uploadQuoteLogoSaga),
    fork(getQuoteItemsListSaga),
    fork(deleteQuoteItemSaga),
    fork(createQuoteItemsSaga),
    fork(updateQuoteItemSaga),
    fork(getQuoteTemplateSaga),
    fork(exportQuotationExcelSaga),
  ]);
}
