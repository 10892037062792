const actions = {
  GET_QUOTES_LIST: "GET_QUOTES_LIST",
  GET_QUOTES_LIST_SUCCESS: "GET_QUOTES_LIST_SUCCESS",
  GET_QUOTES_LIST_ERROR: "GET_QUOTES_LIST_ERROR",

  CREATE_QUOTES: "CREATE_QUOTES",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  DELETE_QUOTE: "DELETE_QUOTE",
  UPLOAD_QUOTE_LOGO: "UPLOAD_QUOTE_LOGO",

  GET_QUOTE_ITEMS_LIST: "GET_QUOTE_ITEMS_LIST",
  GET_QUOTE_ITEMS_LIST_SUCCESS: "GET_QUOTE_ITEMS_LIST_SUCCESS",
  GET_QUOTE_ITEMS_LIST_ERROR: "GET_QUOTE_ITEMS_LIST_ERROR",

  DELETE_QUOTE_ITEM: "DELETE_QUOTE_ITEM",
  CREATE_QUOTE_ITEMS: "CREATE_QUOTE_ITEMS",
  UPDATE_QUOTE_ITEM: "UPDATE_QUOTE_ITEM",

  GET_QUOTE_TEMPLATE: "GET_QUOTE_TEMPLATE",
  EXPORT_QUOTATION_EXCEL: "EXPORT_QUOTATION_EXCEL",

  _getQuotesList: (model, _cbError) => ({
    type: actions.GET_QUOTES_LIST,
    payload: { model },
    _cbError,
  }),

  _createQuotes: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_QUOTES,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _updateQuote: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_QUOTE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _deleteQuote: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_QUOTE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _uploadQuoteLogo: (body, _cbSuccess, _cbError) => ({
    type: actions.UPLOAD_QUOTE_LOGO,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),

  _getQuoteItemsList: (model, _cbError) => ({
    type: actions.GET_QUOTE_ITEMS_LIST,
    payload: { model },
    _cbError,
  }),

  _deleteQuoteItem: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_QUOTE_ITEM,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _createQuoteItems: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_QUOTE_ITEMS,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _updateQuoteItem: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_QUOTE_ITEM,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _getQuoteTemplate: (model, _cbSuccess, _cbError) => ({
    type: actions.GET_QUOTE_TEMPLATE,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),

  _exportQuotationExcel: (model, _cbSuccess, _cbError) => ({
    type: actions.EXPORT_QUOTATION_EXCEL,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
};

export default actions;
