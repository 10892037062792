import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Brands from "@iso/redux/brands/reducer";
import Common from "@iso/redux/common/reducer";
import Consignee from "@iso/redux/consignee/reducer";
import Invoices from "@iso/redux/invoices/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";
import MarketingItems from "@iso/redux/marketingItems/reducer";
import Quotes from "@iso/redux/quotes/reducer";
import Reports from "@iso/redux/reports/reducer";
import Suppliers from "@iso/redux/suppliers/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import User from "@iso/redux/users/reducer";
import { combineReducers } from "redux";

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Common,
  User,
  Consignee,
  Suppliers,
  MarketingItems,
  Invoices,
  Brands,
  Reports,
  Quotes,
});
