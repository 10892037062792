const actions = {
  GET_MARKETING_ITEMS: "GET_MARKETING_ITEMS",
  GET_MARKETING_ITEMS_SUCCESS: "GET_MARKETING_ITEMS_SUCCESS",
  GET_MARKETING_ITEMS_ERROR: "GET_MARKETING_ITEMS_ERROR",

  CREATE_MARKETING_ITEM: "CREATE_MARKETING_ITEM",
  UPDATE_MARKETING_ITEM: "UPDATE_MARKETING_ITEM",
  DELETE_MARKETING_ITEM: "DELETE_MARKETING_ITEM",
  IMPORT_MARKETING_ITEMS: "IMPORT_MARKETING_ITEMS",
  DOWNLOAD_TEMPLATE: "DOWNLOAD_TEMPLATE",
  EXPORT_MARKETING_ITEMS: "EXPORT_MARKETING_ITEMS",

  //#region CRUD
  getMarketingItems: (model, _cbError) => ({
    type: actions.GET_MARKETING_ITEMS,
    payload: { model },
    _cbError,
  }),
  deleteMarketingItem: (model, _cbSuccess, _cbError) => ({
    type: actions.DELETE_MARKETING_ITEM,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  createMarketingItem: (model, _cbSuccess, _cbError) => ({
    type: actions.CREATE_MARKETING_ITEM,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  updateMarketingItem: (model, _cbSuccess, _cbError) => ({
    type: actions.UPDATE_MARKETING_ITEM,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  importMarketingItems: (body, _cbSuccess, _cbError) => ({
    type: actions.IMPORT_MARKETING_ITEMS,
    payload: { body },
    _cbSuccess,
    _cbError,
  }),
  downloadTemplate: (_cbSuccess, _cbError) => ({
    type: actions.DOWNLOAD_TEMPLATE,
    _cbSuccess,
    _cbError,
  }),
  exportMarketingItems: (model, _cbSuccess, _cbError) => ({
    type: actions.EXPORT_MARKETING_ITEMS,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
  //#endregion
};

export default actions;
