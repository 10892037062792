import { deleteToken } from "@iso/lib/helpers/localStorage";
import axios from "axios";
import { notification } from "antd";
// import swal from "sweetalert";
// import {
//   NotiType,
//   _notiError,
//   _notiSuccess,
// } from "@iso/components/Notification";

const BASE_API_URL = process.env.REACT_APP_API_KEY || "http://localhost:6880";
// const BASE_API_URL = 'http://localhost:3000'

export const api = axios.create({
  baseURL: BASE_API_URL,
  timeout: 30000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const {
      response: { status },
    } = error;
    if (status === 401) {
      deleteToken();
      window.location.href = "/signin";
    }
    if (status === 403) {
      window.location.href = "/403";
    }
    if (status === 426) {
      notification["error"]({
        message: "System has been updated, please signin again !",
      });
      deleteToken();
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

api.defaults.headers.common["Authorization"] =
  localStorage.getItem("USER_TOKEN") === null
    ? null
    : `Bearer ${localStorage.getItem("USER_TOKEN")}`;

export function setAuthorization(token) {
  api.defaults.headers.common["Authorization"] =
    token === null ? token : `Bearer ${token}`;
}

export function removeAuthorization() {
  //for Logout
  setAuthorization(null);
}
