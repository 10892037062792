import actions from "./actions";

const initState = {
  quotesList: [],
  totalItems: 0,
  loading: false,
  quoteItemsList: [],
  budgetYears: [],
  commonInfo: {
    brandId: null,
    brandName: null,
    brandCurrency: null,
    budgetId: null,
    budgetYear: null,
    supplierId: null,
    supplierName: null,
    ref: null,
  },
};

export default function quoteReducers(state = initState, action) {
  switch (action.type) {
    case actions.GET_QUOTES_LIST:
      return { ...state, loading: true };

    case actions.GET_QUOTES_LIST_SUCCESS: {
      const { list, total, years } = action.payload;
      return {
        ...state,
        quotesList: list,
        totalItems: total,
        loading: false,
        budgetYears: years,
      };
    }
    case actions.GET_QUOTES_LIST_ERROR:
      return {
        ...state,
        quotesList: [],
        totalItems: 0,
        loading: false,
        budgetYears: [],
      };

    case actions.GET_QUOTE_ITEMS_LIST:
      return { ...state, loading: true };

    case actions.GET_QUOTE_ITEMS_LIST_SUCCESS: {
      const { data } = action.payload;
      const {
        list,
        total,
        brandId,
        brandName,
        brandCurrency,
        budgetId,
        budgetYear,
        supplierId,
        supplierName,
        quoteRef,
      } = data;
      return {
        ...state,
        loading: false,
        quoteItemsList: list?.length ? list : [],
        totalItems: total || 0,
        commonInfo: {
          brandId: brandId,
          brandName: brandName,
          budgetId: budgetId,
          budgetYear: budgetYear,
          brandCurrency: brandCurrency,
          supplierId: supplierId,
          supplierName: supplierName,
          ref: quoteRef,
        },
      };
    }

    case actions.GET_QUOTE_ITEMS_LIST_ERROR:
      return {
        ...state,
        loading: false,
        quoteItemsList: [],
        totalItems: 0,
        commonInfo: {
          brandId: null,
          brandName: null,
          brandCurrency: null,
          budgetId: null,
          budgetYear: null,
          supplierId: null,
          supplierName: null,
        },
      };

    default:
      return state;
  }
}
