import * as reportService from "@iso/services/reports";
import * as moment from "moment";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region TOTAL REPORT
export function* getReportsSaga() {
  yield takeEvery(actions.GET_REPORTS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(reportService.getReportsService, model);
      if (response.data.success) {
        const { data } = response.data;
        const { type } = model;
        yield put({
          type: actions.GET_REPORTS_SUCCESS,
          payload: { data, type },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_REPORTS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_REPORTS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* exportReportSaga() {
  yield takeEvery(
    actions.EXPORT_REPORT,
    function* ({ payload, _cbSuccess, _cbError }) {
      try {
        const { model } = payload;
        const TOTAL = "1";
        var response = yield call(reportService.exportReportService, model);
        if (response.status === 200) {
          yield _cbSuccess();
          let date = moment(new Date(new Date())).format("YYYY-MM-DD_HH-mm-ss");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          const fileName =
            (model?.type === TOTAL
              ? `${model?.name}`
              : `${model?.name}_${`${date}`}`) + ".xlsx";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          if (response && response.data)
            yield _cbError(response.data.message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

//#endregion

export default function* rootSaga() {
  yield all([fork(getReportsSaga), fork(exportReportSaga)]);
}
