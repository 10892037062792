import { ApiRouters } from "@iso/utils/apiRouters";
import { api } from "@iso/utils/axios.configs";
import axios from "axios";

function optionsAxios(url, method, model, data) {
  return {
    baseURL: url,
    method: method,
    timeout: 300000,
    responseType: "blob",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        localStorage.getItem("USER_TOKEN") === null
          ? null
          : `Bearer ${localStorage.getItem("USER_TOKEN")}`,
    },
    params: model,
    data: data,
  };
}
export const getQuotesListService = (model) => {
  const url = ApiRouters.QUOTES;
  return api.get(url, { params: model });
};

export const deleteQuoteService = (model) => {
  const url = ApiRouters.QUOTES;
  return api.delete(url, { params: model });
};

export const createQuoteService = (body) => {
  const url = ApiRouters.QUOTES;
  return api.post(url, body);
};

export const updateQuoteService = (model) => {
  const url = `${ApiRouters.QUOTES}/${model.id}`;
  return api.put(url, model);
};

export const getQuoteTemplateService = (model) => {
  const url = `${ApiRouters.QUOTES}/getQuotesTemplate`;
  return api.get(url, { params: model });
};

export const exportQuotationExcelService = (model) => {
  var url =
    process.env.REACT_APP_API_KEY +
    `${ApiRouters.QUOTES}/exportQuote`;
  return axios(optionsAxios(url, "get", model, null));
};

export const uploadQuoteLogoService = (body) => {
  const url = `${ApiRouters.QUOTE_ITEMS}/UploadImage`;
  return api.post(url, body);
};

export const getQuoteItemsListService = (model) => {
  const url = `${ApiRouters.QUOTE_ITEMS}/${model.budgetId}`;
  return api.get(url, { params: model });
};

export const deleteQuoteItemService = (model) => {
  const url = ApiRouters.QUOTE_ITEMS;
  return api.delete(url, { params: model });
};

export const createQuoteItemsService = (model) => {
  const url = `${ApiRouters.QUOTE_ITEMS}/${model.quoteId}`;
  return api.post(url, model.body);
};

export const updateQuoteItemService = (model) => {
  const url = `${ApiRouters.QUOTE_ITEMS}/${model.quoteItemId}`;
  return api.put(url, model.body);
};
