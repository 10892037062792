const actions = {
  GET_REPORTS: "GET_REPORTS",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_ERROR: "GET_REPORTS_ERROR",

  getReports: (model, _cbError) => ({
    type: actions.GET_REPORTS,
    payload: { model },
    _cbError,
  }),

  EXPORT_REPORT: "EXPORT_REPORT",
  exportReport: (model, _cbSuccess, _cbError) => ({
    type: actions.EXPORT_REPORT,
    payload: { model },
    _cbSuccess,
    _cbError,
  }),
};

export default actions;
