import * as invoicesService from "@iso/services/invoices";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getInvoicesListSaga() {
  yield takeEvery(actions.GET_INVOICES_LIST, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(invoicesService.getInvoicesListService, model);
      if (response.data.success) {
        const { list: data, total: totalItems, brandName, budgetYear, brandCurrency, years } = response.data.data;
        yield put({
          type: actions.GET_INVOICES_LIST_SUCCESS,
          payload: { data, totalItems, brandName, budgetYear, brandCurrency, years },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_INVOICES_LIST_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_INVOICES_LIST_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteInvoiceSaga() {
  yield takeEvery(
    actions.DELETE_INVOICE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(invoicesService.deleteInvoiceService, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createInvoiceSaga() {
  yield takeEvery(
    actions.CREATE_INVOICE,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        var response = yield call(invoicesService.createInvoiceService, model);
        if (response.data.success === true) {
          const { data } = response.data;
          yield _cbSuccess(data);
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* getInvoiceSaga() {
  yield takeEvery(actions.GET_INVOICE, function* ({ payload, _cbSuccess, _cbError }) {
    const { model } = payload;
    try {
      var response = yield call(invoicesService.getInvoiceService, model);
      if (response.data.success) {
        const { data } = response.data;
        yield _cbSuccess(data);
        // let { id: invoiceId, isPreview } = model;
        // yield put({
        //   type: actions.GET_INVOICE_SUCCESS,
        //   payload: { data, invoiceId, isPreview },
        // });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        // yield put({
        //   type: actions.GET_INVOICE_ERROR,
        // });
      }
    } catch (e) {
      // yield put({
      //   type: actions.GET_INVOICE_ERROR,
      // });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getInvoicesListSaga),
    fork(deleteInvoiceSaga),
    fork(createInvoiceSaga),
    fork(getInvoiceSaga),
  ]);
}
