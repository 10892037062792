import * as suppliersService from "@iso/services/suppliers";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import actions from "./actions";

//#region CRUD
export function* getSuppliersSaga() {
  yield takeEvery(actions.GET_SUPPLIERS, function* ({ payload, _cbError }) {
    const { model } = payload;
    try {
      const response = yield call(suppliersService.getSuppliersService, model);
      if (response.data.success) {
        const { list: data, total: totalItems } = response.data.data;
        yield put({
          type: actions.GET_SUPPLIERS_SUCCESS,
          payload: { data, totalItems },
        });
      } else {
        let { message } = response.data;
        yield _cbError(message);
        yield put({
          type: actions.GET_SUPPLIERS_ERROR,
        });
      }
    } catch (e) {
      yield put({
        type: actions.GET_SUPPLIERS_ERROR,
      });
      if (e.response && e.response.data)
        yield _cbError(e.response.data.message);
      else yield _cbError();
    }
  });
}

export function* deleteSupplierSaga() {
  yield takeEvery(
    actions.DELETE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          suppliersService.deleteSupplierService,
          model
        );
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          const { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}

export function* createSupplierSaga() {
  yield takeEvery(
    actions.CREATE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(
          suppliersService.createSupplierService,
          model
        );
        if (response.data.success) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
export function* updateSupplierSaga() {
  yield takeEvery(
    actions.UPDATE_SUPPLIER,
    function* ({ payload, _cbSuccess, _cbError }) {
      const { model } = payload;
      try {
        const response = yield call(suppliersService.updateSuppliers, model);
        if (response.data.success === true) {
          yield _cbSuccess();
        } else {
          let { message } = response.data;
          yield _cbError(message);
        }
      } catch (e) {
        if (e.response && e.response.data)
          yield _cbError(e.response.data.message);
        else yield _cbError();
      }
    }
  );
}
//#endregion

export default function* rootSaga() {
  yield all([
    fork(getSuppliersSaga),
    fork(deleteSupplierSaga),
    fork(createSupplierSaga),
    fork(updateSupplierSaga),
  ]);
}
